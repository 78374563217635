// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-[id]-js": () => import("./../../../src/pages/category/[id].js" /* webpackChunkName: "component---src-pages-category-[id]-js" */),
  "component---src-pages-detail-[id]-js": () => import("./../../../src/pages/detail/[id].js" /* webpackChunkName: "component---src-pages-detail-[id]-js" */),
  "component---src-pages-hotelservices-js": () => import("./../../../src/pages/hotelservices.js" /* webpackChunkName: "component---src-pages-hotelservices-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

