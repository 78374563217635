import fetch from "isomorphic-fetch";
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from "@apollo/client";

const httpLink = new HttpLink({ uri: "https://graphql.contentful.com/content/v1/spaces/ppwuh7rf08h0/" });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: "Bearer muu8zDYGmxY1sUy37gWUaPtiNOPjZTKiHoyGveY1Ujc",
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  fetch: fetch,

  cache: new InMemoryCache(),
});
